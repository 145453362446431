@font-face {
    font-family: 'CCMrMamoulianBlatherW00-Rg';
    src: url('../src/assets/fonts/CCMrMamoulianBlather.otf') format('opentype');
}

* {
    margin: 0;
    font-family: 'CCMrMamoulianBlatherW00-Rg';
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}


body {
    width: 100%;
    font-size: .875vw;
    width: min(100vw, 600px);
    min-height: 100vh;
    margin: 0 auto;
    background: url('./assets/images/noise.png'), linear-gradient(90deg, #00897b 7.58%, #00b3a0  86.32%);
    background-repeat: repeat;
    display: grid;

    --gap: min(7.5vw, 40px);
    grid-template-columns: [full-start] var(--gap) [content-start] 1fr [content-end] var(--gap) [full-end]
}

header {
    width: 100%;
    padding: min(20vw, 120px) 0 0; 
    grid-column: full;
    display: inherit;
    grid-template-columns: inherit;
    grid-template-rows: auto 1fr;
    row-gap: min(10vw, 50px);
    position: relative;
}

main {
    margin-top: 5rem;
    display: inherit;
    grid-column: full;
    grid-template-columns: inherit;
    margin-bottom: max(-15vw, -9rem);
}

.top_decoration {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.lego_logo {
    grid-column: content;
    z-index: 1;
    width: 100%;
}

.hand {
    grid-column: full;
    justify-self: end;
    align-self: end;
    width: 95%;
    grid-row: 2;
}

.hand > img {
   width: 100%;
   object-fit: contain;
}

a {
    place-self: end;
    grid-row: 2;
    grid-column: content;
    margin-inline: auto;
    z-index: 100;
    text-decoration: none;
}

.ar-btn {
    border: 3px solid #fff;
    border-radius: 45px;
    background-color: #00bf56;
    width: min(60vw, 360px);
    height: min(15vw, 85px); 

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.4rem;
   
    margin-bottom: min(45vw, 270px);
}

.ar-btn img {
    width: min(10vw, 60px);
    height: min(10vw, 60px);
}

.ar-btn span {
    display: inline-block;
    font-size: min(6vw, 3.8rem);
    line-height: 1;
    color: #fff;
}

.scroll_down {
    grid-row: 2;
    grid-column: content;
    place-self: end;
    margin-inline: auto;
    margin-bottom: min(15vw, 90px);
}

.video_section {
    grid-column: content;
    border-radius: 30px;
    overflow: hidden;
    width: 100%;
}

.plyr {
    border-radius: 30px;
}

.video_section > video {
    width: 100%;
}

.instructions {
    display: inherit;
    grid-column: full;
    grid-template-columns: inherit;
    background-image: url('./assets/images/rainbow.png');
    background-size: 100% 100%;
    margin-top: 2.5rem;
    padding: 7.5rem 0 min(80vw, 40rem);
}

.instruction {
    grid-column: content;
    display: flex;
    flex-flow: column nowrap;
}

.number {
    width: min(10vw, 5rem);
    margin: 0 2rem;
}

.illustration {
    width: min(40vw, 30rem);
    margin: min(14vw, 8rem) 0 min(20vw, 10rem) 32%;
}

footer {
    width: 100%;
    grid-column: full;
    position: relative;
}

.copyright {
    font-size: 1.2rem;
    color: #fff;
    position: absolute;
    left: 50%;
    bottom: 1rem;
    z-index: 3;
    transform: translateX(-50%);
}

.decoration {
    width: 100%;
    position: relative;
    bottom: -1%;
}

.friends-image {
    width: 90%;
    position: absolute;
    z-index: 2;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
} 

