@font-face {
  font-family: CCMrMamoulianBlatherW00-Rg;
  src: url("CCMrMamoulianBlather.4d9fa784.otf") format("opentype");
}

* {
  box-sizing: border-box;
  margin: 0;
  font-family: CCMrMamoulianBlatherW00-Rg;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  width: 100%;
  width: min(100vw, 600px);
  min-height: 100vh;
  --gap: min(7.5vw, 40px);
  grid-template-columns: [full-start] var(--gap) [content-start] 1fr [content-end] var(--gap) [full-end];
  background-color: #0000;
  background-image: url("noise.bef1de95.png"), linear-gradient(90deg, #00897b 7.58%, #00b3a0 86.32%);
  background-position: 0 0, 0 0;
  background-repeat: repeat;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  margin: 0 auto;
  font-size: .875vw;
  display: grid;
}

header {
  width: 100%;
  display: inherit;
  grid-column: full;
  grid-template-columns: inherit;
  grid-template-rows: auto 1fr;
  row-gap: min(10vw, 50px);
  padding: min(20vw, 120px) 0 0;
  position: relative;
}

main {
  display: inherit;
  grid-column: full;
  grid-template-columns: inherit;
  margin-top: 5rem;
  margin-bottom: max(-15vw, -9rem);
}

.top_decoration {
  width: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.lego_logo {
  z-index: 1;
  width: 100%;
  grid-column: content;
}

.hand {
  width: 95%;
  grid-area: 2 / full;
  place-self: end;
}

.hand > img {
  width: 100%;
  object-fit: contain;
}

a {
  z-index: 100;
  grid-area: 2 / content;
  place-self: end;
  margin-inline: auto;
  text-decoration: none;
}

.ar-btn {
  width: min(60vw, 360px);
  height: min(15vw, 85px);
  background-color: #00bf56;
  border: 3px solid #fff;
  border-radius: 45px;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
  margin-bottom: min(45vw, 270px);
  display: flex;
}

.ar-btn img {
  width: min(10vw, 60px);
  height: min(10vw, 60px);
}

.ar-btn span {
  color: #fff;
  font-size: min(6vw, 3.8rem);
  line-height: 1;
  display: inline-block;
}

.scroll_down {
  margin-inline: auto;
  grid-area: 2 / content;
  place-self: end;
  margin-bottom: min(15vw, 90px);
}

.video_section {
  width: 100%;
  border-radius: 30px;
  grid-column: content;
  overflow: hidden;
}

.plyr {
  border-radius: 30px;
}

.video_section > video {
  width: 100%;
}

.instructions {
  display: inherit;
  grid-column: full;
  grid-template-columns: inherit;
  background-image: url("rainbow.1e1a7d8b.png");
  background-size: 100% 100%;
  margin-top: 2.5rem;
  padding: 7.5rem 0 min(80vw, 40rem);
}

.instruction {
  flex-flow: column;
  grid-column: content;
  display: flex;
}

.number {
  width: min(10vw, 5rem);
  margin: 0 2rem;
}

.illustration {
  width: min(40vw, 30rem);
  margin: min(14vw, 8rem) 0 min(20vw, 10rem) 32%;
}

footer {
  width: 100%;
  grid-column: full;
  position: relative;
}

.copyright {
  color: #fff;
  z-index: 3;
  font-size: 1.2rem;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.decoration {
  width: 100%;
  position: relative;
  bottom: -1%;
}

.friends-image {
  width: 90%;
  z-index: 2;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
}

/*# sourceMappingURL=index.726196c5.css.map */
